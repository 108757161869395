<template>
  <v-main>
    <h2 class="mt-2 mb-8 titleColor--text">Fonte de dados - eSocial</h2>
    <v-alert dismissible v-if="notAccepted" color="warning"> Tipo de arquivo não permitido</v-alert>

    <v-row class="mt-1">
      <v-col>
        <v-card>
          <div align="center">
            <v-alert dismissible width="600px" align="center" v-model="fileAlreadyExists" color="warning">
              Arquivo já existe no sistema</v-alert
            >
          </div>
          <vueDropzone
            id="dropzone"
            ref="myVueDropzone"
            :options="dropzoneOptions"
            @vdropzone-error="failed"
            @vdropzone-file-added="formatFile"
            @vdropzone-complete="removeAfterUpload"
            @vdropzone-canceled="cancel"
          />
        </v-card>
      </v-col>
    </v-row>

    <v-col class="mt-8 mb-4">Arquivos Enviados</v-col>
    <table-status-uploads ref="tableStatus" />
  </v-main>
</template>

<script>
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

import { mapGetters } from 'vuex';

import vue2Dropzone from 'vue2-dropzone';

export default {
  name: 'Dashboard',

  components: {
    vueDropzone: vue2Dropzone,
    tableStatusUploads: () => import('./components/TableStatusUploads.vue'),
  },

  data() {
    const msgMaxFilesizeGb = 5;
    const maxFilesizeMb = 5000;
    const upload_time_out = 60 * 1000 * 45; //45 min
    const urlFinal = process.env.VUE_APP_API + '/esocial/file-process/upload/';
    return {
      fileAlreadyExists: false,
      notAccepted: false,
      errorMessage: '',
      dropzoneOptions: {
        parallelUploads: 5,
        autoProcessQue: true,
        url: urlFinal,
        acceptedFiles:
          'application/x-zip-compressed,application/zip,application/octet-stream,application/x-compress,application/x-compressed,multipart/x-zip',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_Token')}`,
          ipAddress: localStorage.getItem('ipAddress'),
        },
        maxFilesize: maxFilesizeMb,
        timeout: upload_time_out,
        maxFiles: 25,
        thumbnailWidth: 150, // px
        thumbnailHeight: 150,
        addRemoveLinks: true,
        dictDefaultMessage:
          "<i class='mdi mdi-cloud-upload' style='font-size: 3em;'><br/></i><b>Selecione até 25 arquivos para envio simultâneos</b> ou arraste e solte eles aqui <br> Tamanho máximo por arquivo: " +
          msgMaxFilesizeGb +
          ' GB<br> Formato: .zip',
        dictCancelUpload: 'Cancelar upload',
        dictCancelUploadConfirmation: 'Você tem certeza que deseja cancelar o upload?',
        dictInvalidFileType: 'Você não pode subir esse tipo de arquivo',
        dictMaxFilesExceeded: 'Não pode subir mais arquivos, maximo 5.',
        dictRemoveFile: 'Remover arquivo',
        dictResponseError: 'Servidor respondeu com código: {{statusCode}} code.',
        dictUploadCanceled: 'Upload cancelado.',
        // init: function () {
        //   this.on('error', function (file, message) {
        //     console.log(message);
        //     if (message.statusErro === 'CONFLICT') {
        //       this.fileAlreadyExists = true;
        //     }
        //   });
        // },
      },
    };
  },
  computed: {
    ...mapGetters({
      usuario: 'user/getUserDetails',
    }),
  },

  methods: {
    removeAfterUpload(file) {
      this.$refs.myVueDropzone.removeFile(file);
      this.$refs.tableStatus.$emit('refresh');
    },
    formatFile(formData) {
      //console.log(formData);
      if (
        formData.type === 'application/x-zip-compressed' ||
        formData.type === 'application/zip' ||
        formData.type === 'application/octet-stream' ||
        formData.type === 'application/x-compress' ||
        formData.type === 'application/x-compressed' ||
        formData.type === 'multipart/x-zip'
      ) {
        this.notAccepted = false;
        let file = new FormData();
        file.append('file', formData);
        file.append('user', this.usuario.username);
        return file;
      } else {
        this.notAccepted = true;
        this.$refs.myVueDropzone.removeFile(formData);
        file.xhr.abort();
      }
    },

    cancel(file) {
      file.xhr.abort();
    },

    failed(file, message) {
      let parse = { message: 'Falha no servidor, tente em alguns minutos' };
      const elements = document.querySelectorAll('.dz-error-message span');
      const lastElement = elements[elements.length - 1];
      lastElement.textContent = parse.message;

      if (message.statusErro === 'CONFLICT') {
        this.fileAlreadyExists = true;
      }
    },
  },
};
</script>
<style scoped>
@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
</style>
