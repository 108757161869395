var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    [
      _c("h2", { staticClass: "mt-2 mb-8 titleColor--text" }, [
        _vm._v("Fonte de dados - eSocial"),
      ]),
      _vm.notAccepted
        ? _c("v-alert", { attrs: { dismissible: "", color: "warning" } }, [
            _vm._v(" Tipo de arquivo não permitido"),
          ])
        : _vm._e(),
      _c(
        "v-row",
        { staticClass: "mt-1" },
        [
          _c(
            "v-col",
            [
              _c(
                "v-card",
                [
                  _c(
                    "div",
                    { attrs: { align: "center" } },
                    [
                      _c(
                        "v-alert",
                        {
                          attrs: {
                            dismissible: "",
                            width: "600px",
                            align: "center",
                            color: "warning",
                          },
                          model: {
                            value: _vm.fileAlreadyExists,
                            callback: function ($$v) {
                              _vm.fileAlreadyExists = $$v
                            },
                            expression: "fileAlreadyExists",
                          },
                        },
                        [_vm._v(" Arquivo já existe no sistema")]
                      ),
                    ],
                    1
                  ),
                  _c("vueDropzone", {
                    ref: "myVueDropzone",
                    attrs: { id: "dropzone", options: _vm.dropzoneOptions },
                    on: {
                      "vdropzone-error": _vm.failed,
                      "vdropzone-file-added": _vm.formatFile,
                      "vdropzone-complete": _vm.removeAfterUpload,
                      "vdropzone-canceled": _vm.cancel,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-col", { staticClass: "mt-8 mb-4" }, [_vm._v("Arquivos Enviados")]),
      _c("table-status-uploads", { ref: "tableStatus" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }